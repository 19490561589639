import { useState } from "react";
import { APIs } from "../../../../utils/ApiUtils";
import CONSTANTS from "../../../const/constants";
import "./ChatFooter.css";
import { v4 as uuidv4 } from "uuid";
import sendButtonIcon from "../../../../assets/icon-back-arrow.svg";

function ChatFooter(props) {
    const [state, setState] = useState({
        inputValue: "",
    });

    const sendBtnClick = () => {
        if (
            props.onChatSendBtnClick &&
            state.inputValue.trim().length > 0 &&
            props.disabledInput == false
        ) {
            props.onChatSendBtnClick(APIs.CHAT_API, {
                query: state.inputValue,
            });
            setState({
                inputValue: "",
            });
            CONSTANTS.scrollChatBody("chat-body-inner-div");
        }
    };

    const handleStartOverClick = () => {
        localStorage.removeItem(CONSTANTS.TOKEN_STORE_KEY);
        props.setState({
            data: [
                {
                    from: CONSTANTS.userType.BOT,
                    id: uuidv4(),
                    time_stamp: "",
                    message_before: "Hi, How can I help you?",
                    card_data: [],
                    message_after: "",
                    cta: [],
                    search_parameters: {},
                    buttons: [],
                    loading: false,
                    endOfConversation: false,
                },
            ],
        });
    };

    let chat_footer_inner_div_style = {};

    if (props.popup) {
        chat_footer_inner_div_style.borderBottomLeftRadius = "30px";
        chat_footer_inner_div_style.borderBottomRightRadius = "30px";
    } else {
        chat_footer_inner_div_style.borderBottomLeftRadius = "0px";
        chat_footer_inner_div_style.borderBottomRightRadius = "0px";
    }
    return (
        <div className="chat-footer-container-div">
            <div
                className="chat-footer-inner-div"
                style={chat_footer_inner_div_style}
            >
                <div className="chat-footer-input-outer-div">
                    <div className="chat-footer-input-inner-div">
                        <div className="chat-footer-input-div">
                            <input
                                placeholder="Reply to Legacy"
                                className="chat-footer-input"
                                type="text"
                                value={state.inputValue}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") sendBtnClick();
                                }}
                                onChange={(event) => {
                                    if (props.disabledInput == true) return;
                                    setState({
                                        inputValue: event.target.value,
                                    });
                                }}
                            ></input>
                        </div>
                    </div>
                    <button
                        className="send-button"
                        onClick={() => {
                            sendBtnClick();
                        }}
                        type="button"
                        style={{
                            opacity: props.disabledInput ? "0.5" : "1",
                            cursor: props.disabledInput ? "auto" : "pointer",
                            backgroundColor: props.colorTheme.color,
                        }}
                    >
                        <div className="send-button-div">
                            <img
                                className="mic-icon"
                                src={sendButtonIcon}
                            ></img>
                        </div>
                    </button>
                </div>
                {!props.popup && (
                    <div className="navigate-outer-div">
                        <div className="navigate-inner-div">
                            <p className="navigate-p">
                                <a
                                    className="navigate-a"
                                    href={`https://www.legacy.com/obituaries/search?firstName=${
                                        props.firstName ? props.firstName : ""
                                    }&lastName=${
                                        props.lastName ? props.lastName : ""
                                    }&dateRange=alltime`}
                                    target="_blank"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.dataLayer.push({
                                          event: 'GAEvent',
                                          eventCategory: 'by_page_search_chatbot',
                                          eventAction: 'by_page_search_chatbot',
                                          eventLabel: 'traditional_search',
                                          eventValue: '',
                                        });
                                        
                                        setTimeout(() => {
                                          window.open(`https://www.legacy.com/obituaries/search?firstName=${
                                            props.firstName ? props.firstName : ""
                                        }&lastName=${
                                            props.lastName ? props.lastName : ""
                                        }&dateRange=alltime`, "_blank");
                                        }, 250);
                                      }}
                                >
                                    Use Traditional Search Instead
                                </a>
                            </p>
                        </div>
                        {/* <div className="start-over-inner-div">
                            <p className="start-over-p">
                                <a
                                    className="start-over-a"
                                    onClick={handleStartOverClick}
                                    target="_blank"
                                >
                                    Start Over
                                </a>
                            </p>
                        </div> */}
                    </div>
                )}
            </div>
        </div>
    );
}
export default ChatFooter;
