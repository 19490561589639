import ChatHeader from "./view/pages/chat/header/ChatHeader";
import ChatBody from "./view/pages/chat/body/ChatBody";
import ChatFooter from "./view/pages/chat/footer/ChatFooter";
import { useState, useEffect, useRef } from "react";
import CONSTANTS from "./view/const/constants";
import { v4 as uuidv4 } from "uuid";
import { get, post, APIs } from "./utils/ApiUtils";

function Chat({
    popup,
    title,
    colorTheme,
    showHistory,
    historyAPIEndpoint,
    chatBotAPIEndpoint,
    BotButtonsAPIEndpoint,
}) {
    APIs.CHAT_API = chatBotAPIEndpoint;
    APIs.CHAT_HISTORY_API = historyAPIEndpoint;
    APIs.BOT_RESPONSE_BUTTON_API = BotButtonsAPIEndpoint;
    const initialized = useRef(false);
    const [state, setState] = useState({
        data: [],
    });
    const searchParams = new URLSearchParams(window.location.search);
    const firstName = searchParams.get("firstName");
    const lastName = searchParams.get("lastName");
    const disableInput = useRef(false);

    let colorThemeKeys = Object.keys(colorTheme);
    if (!colorTheme || colorThemeKeys.length === 0) {
        colorTheme = {
            color: "#006699",
            bot: {
                background: "#FFF",
                text: "#000",
            },
            user: {
                background: "#006699",
                text: "#FFF",
            },
        };
    }
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;

            // if (showHistory) {
            //     disableInput.current = true;
            //     getChatHistory();
            // } else {
            //     handleQueryParameters();
            // }

            localStorage.removeItem(CONSTANTS.TOKEN_STORE_KEY);
            handleQueryParameters();
        }
    }, []);

    const handleQueryParameters = () => {
        if (firstName === null && lastName === null) {
            setState({
                data: [
                    {
                        from: CONSTANTS.userType.BOT,
                        id: uuidv4(),
                        time_stamp: "",
                        message_before: "Hi, How can I help you?",
                        card_data: [],
                        message_after: "",
                        cta: [],
                        buttons: [],
                        loading: false,
                        search_parameters: {},
                        endOfConversation: false,
                    },
                ],
            });
        } else {
            const search_parameters = {
                first_name: firstName,
                last_name: lastName,
            };
            onChatSendBtnClick(chatBotAPIEndpoint, {
                query: `Can you help me find an obituary for <i>${
                    firstName != "" ? firstName + " " : ""
                }${lastName != "" ? lastName : ""}</i>?`,
                search_parameters: search_parameters,
            });
        }
    };

    const getChatHistory = () => {
        get(historyAPIEndpoint)
            .then((response) => {
                const dataArr = response?.data?.chat_history;
                if (dataArr.length === 0) {
                    if (firstName === null && lastName === null) {
                        dataArr.push({
                            from: CONSTANTS.userType.BOT,
                            id: uuidv4(),
                            time_stamp: "",
                            message_before: "Hi, How can I help you?",
                            card_data: [],
                            message_after: "",
                            cta: [],
                            search_parameters: {},
                            buttons: [],
                            endOfConversation: false,
                        });
                    } else {
                        const search_parameters = {
                            first_name: firstName,
                            last_name: lastName,
                        };
                        onChatSendBtnClick(chatBotAPIEndpoint, {
                            query: `Can you help me find an obituary for <u>${
                                firstName != "" ? firstName + " " : ""
                            }${lastName != "" ? lastName : ""}</u>?`,
                            search_parameters: search_parameters,
                        });
                    }
                }
                dataArr.map((chat) => {
                    chat.id = uuidv4();
                    // chat.id = 1;
                    chat.loading = false;
                    chat.endOfConversation = chat.grey;
                });
                setState({
                    data: dataArr,
                });
                dataArr[dataArr.length - 1].loading = false;
                disableInput.current = false;
                setTimeout(() => {
                    CONSTANTS.scrollChatBody("chat-body-inner-div");
                }, 0);
            })
            .catch((error) => {
                handleQueryParameters();
            });
    };

    useEffect(() => {
        CONSTANTS.scrollChatBody("chat-body-inner-div");
    }, [state.data]);

    const onChatSendBtnClick = (url, payload, appendUserMsg = true) => {
        if (!("endpoint" in payload)) {
            payload.endpoint = "query_endpoint";
        }
        const dataArr = state.data;
        if (dataArr.length > 0 && payload.endpoint == "query_endpoint") {
            payload.search_parameters =
                dataArr[dataArr.length - 1].search_parameters;
        }
        if (appendUserMsg) {
            dataArr.push({
                from: CONSTANTS.userType.USER,
                id: uuidv4(),
                time_stamp: "",
                text: payload.query,
                loading: false,
                endOfConversation: false,
            });
        }

        dataArr.push({
            from: CONSTANTS.userType.BOT,
            id: uuidv4(),
            time_stamp: "",
            message_before: "",
            card_data: [],
            message_after: "",
            cta: [],
            search_parameters: {},
            buttons: [],
            loading: true,
            endOfConversation: false,
        });
        dataArr[dataArr.length - 1].loading = true;

        const dataArray = state.data;
        disableInput.current = true;
        setState({
            data: [...dataArr],
        });
        CONSTANTS.scrollChatBody("chat-body-inner-div");
        post(url, payload)
            .then((response) => {
                dataArray[dataArray.length - 1].loading = false;
                dataArray[dataArray.length - 1].endOfConversation =
                    response?.data?.data?.response?.grey;
                dataArray[dataArray.length - 1].search_parameters =
                    response?.data?.data?.response?.search_parameters;

                let temp_data = {};
                temp_data.message_before =
                    response?.data?.data?.response?.message_before;
                temp_data.message_after =
                    response?.data?.data?.response?.message_after;
                temp_data.card_data = response?.data?.data?.response?.card_data;
                temp_data.cta = response?.data?.data?.response?.cta;
                temp_data.buttons = response?.data?.data?.response?.buttons;
                temp_data.search_parameters =
                    response?.data?.data?.response?.search_parameters;

                displayMessageBefore(temp_data, dataArr);
                setTimeout(() => {
                    CONSTANTS.scrollChatBody("chat-body-inner-div");
                }, 0);
            })
            .catch((error) => {
                console.error("Error:", error.message);
            });
    };

    const test_response = {
        from: CONSTANTS.userType.BOT,
        id: uuidv4(),
        time_stamp: "",
        message_before:
            'I found 12,286 results for "John Smith". Here are the top 5 results sorted by most recent:',
        card_data: [
            {
                heading: "John Smith",
                content: {
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus est est, viverra at lacinia vel, pharetra et risus. Curabitur vel aliquet leo. Nullam fermentum augue eget justo mattis, at bibendum leo sollicitudin. Nulla diam nulla, sodales ac imperdiet et, ornare eget turpi",
                    link: "https://www.google.com",
                },
            },
            {
                heading: "John Jake Smith",
                content: {
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus est est, viverra at lacinia vel, pharetra et risus. Curabitur vel aliquet leo. Nullam fermentum augue eget justo mattis, at bibendum leo sollicitudin. Nulla diam nulla, sodales ac imperdiet et, ornare eget turpi",
                    link: "https://www.google.com",
                },
                image: "../src/assets/legacy-short-icon.png",
            },
            {
                heading: "John Smith",
                content: {
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus est est, viverra at lacinia vel, pharetra et risus. Curabitur vel aliquet leo. Nullam fermentum augue eget justo mattis, at bibendum leo sollicitudin. Nulla diam nulla, sodales ac imperdiet et, ornare eget turpi",
                    link: "https://www.google.com",
                },
                image: "../src/assets/legacy-short-icon.png",
            },
        ],
        message_after: "How can I help you?",
        cta: [
            { text: "Check Ancestry", action: "https://www.google.com" },
            { text: "Create Obituary", action: "https://www.google.com" },
            {
                text: "Use Traditional Search ",
                action: "https://www.google.com",
            },
        ],
        search_parameters: {},
        buttons: ["Show 5 More Results", "Filter Results"],
        loading: false,
        endOfConversation: false,
    };

    const onChatSendBtnClick_test = (url, payload, appendUserMsg = true) => {
        let dataArray = state.data;
        if (appendUserMsg) {
            dataArray.push({
                from: CONSTANTS.userType.USER,
                id: uuidv4(),
                time_stamp: "",
                text: payload.query,
                loading: false,
                endOfConversation: false,
            });
        }
        dataArray.push({
            from: CONSTANTS.userType.BOT,
            id: uuidv4(),
            time_stamp: "",
            message_before: "",
            card_data: [],
            message_after: "",
            cta: [],
            search_parameters: {},
            buttons: [],
            loading: false,
            endOfConversation: false,
        });
        setState({
            data: [...dataArray],
        });
        CONSTANTS.scrollChatBody("chat-body-inner-div");
        disableInput.current = true;

        displayMessageBefore(test_response, dataArray);
    };

    const displayMessageBefore = (data, dataArray) => {
        let msg_b_arr = data.message_before.split(" ");

        if (msg_b_arr && msg_b_arr.length > 0) {
            var index = 0;
            var msg_before_interval = setInterval(function () {
                dataArray[dataArray.length - 1].message_before +=
                    msg_b_arr[index] + " ";
                setState({
                    data: [...dataArray],
                });
                if (index == msg_b_arr.length - 1) {
                    clearInterval(msg_before_interval);
                    CONSTANTS.scrollChatBody("chat-body-inner-div");
                    displayCards(data, dataArray);
                } else {
                    CONSTANTS.scrollChatBody("chat-body-inner-div");
                    index++;
                }
            }, 175);
        } else {
            displayCards(data, dataArray);
        }
    };

    const displayCards = (data, dataArray) => {
        let cards = data.card_data;

        if (cards && cards.length > 0) {
            var index = 0;
            var cards_interval = setInterval(function () {
                dataArray[dataArray.length - 1].card_data = [
                    ...dataArray[dataArray.length - 1].card_data,
                    cards[index],
                ];
                setState({
                    data: [...dataArray],
                });
                if (index == cards.length - 1) {
                    clearInterval(cards_interval);
                    CONSTANTS.scrollChatBody("chat-body-inner-div");
                    displayMessageAfter(data, dataArray);
                } else {
                    CONSTANTS.scrollChatBody("chat-body-inner-div");
                    index++;
                }
            }, 980);
        } else {
            displayMessageAfter(data, dataArray);
        }
    };

    const displayMessageAfter = (data, dataArray) => {
        let msg_a_arr = data.message_after.split(" ");

        if (msg_a_arr && msg_a_arr.length > 0) {
            var index = 0;
            var msg_after_interval = setInterval(function () {
                dataArray[dataArray.length - 1].message_after +=
                    msg_a_arr[index] + " ";
                setState({
                    data: [...dataArray],
                });
                if (index == msg_a_arr.length - 1) {
                    clearInterval(msg_after_interval);
                    dataArray[dataArray.length - 1].buttons = data.buttons;
                    dataArray[dataArray.length - 1].cta = data.cta;
                    disableInput.current = data.endOfConversation
                        ? true
                        : false;
                    setTimeout(() => {
                        CONSTANTS.scrollChatBody("chat-body-inner-div");
                    }, 0);
                } else {
                    index++;
                }
            }, 175);
        } else {
            dataArray[dataArray.length - 1].buttons = data.buttons;
            dataArray[dataArray.length - 1].cta = data.cta;
            disableInput.current = data.endOfConversation ? true : false;
            setTimeout(() => {
                CONSTANTS.scrollChatBody("chat-body-inner-div");
            }, 0);
        }
    };

    let chat_bot_main_container_style = {
        minHeight: popup ? "" : "100vh",
        borderRadius: popup ? "30px" : "0px",
    };

    return (
        <>
            <ChatHeader popup={popup} title={title} colorTheme={colorTheme} />
            <ChatBody
                data={state.data}
                popup={popup}
                colorTheme={colorTheme}
                // onChatSendBtnClick={onChatSendBtnClick_test}
                onChatSendBtnClick={onChatSendBtnClick}
                setState={setState}
                state={state}
            />
            <ChatFooter
                disabledInput={
                    disableInput.current ||
                    (state.data[state.data.length - 1]?.endOfConversation ===
                    true
                        ? true
                        : false)
                }
                onChatSendBtnClick={onChatSendBtnClick}
                // onChatSendBtnClick={onChatSendBtnClick_test}
                popup={popup}
                firstName={firstName}
                lastName={lastName}
                colorTheme={colorTheme}
                setState={setState}
            />
        </>
    );
}
export default Chat;
