import React from "react";
import "./CTAButton.css";
import "@fontsource/source-sans-pro";

function CTAButton({ id, label, action }) {
    let classes = "cta-response-button ";
    const ctaClickHandler = (event) => {
        console.log(event.target.innerHTML)
        if (event.target.innerHTML == "Search Ancestry.com"){
            window.dataLayer.push({
                event: 'GAEvent',
                eventCategory: 'by_page_search_chatbot',
                eventAction: 'by_page_search_chatbot',
                eventLabel: 'ancestry',
                eventValue: '',
              });
        } else if (event.target.innerHTML == "Create Obituary"){
            window.dataLayer.push({
                event: 'GAEvent',
                eventCategory: 'by_page_search_chatbot',
                eventAction: 'by_page_search_chatbot',
                eventLabel: 'create_obit_no_results',
                eventValue: '',
              });
        } else if (event.target.innerHTML == "Use Traditional Search"){
            window.dataLayer.push({
                event: 'GAEvent',
                eventCategory: 'by_page_search_chatbot',
                eventAction: 'by_page_search_chatbot',
                eventLabel: 'traditional_search_no_results',
                eventValue: '',
              });
        }
        window.open(action, "_blank");
    };
    return (
        <button
            id={id}
            className="cta-response-button cta-response-enable"
            onClick={ctaClickHandler}
        >
            {label}
        </button>
    );
}

export default CTAButton;
