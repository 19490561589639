import React, { useState } from "react";
import "./BotResponse.css";
import Card from "./Card";
import BotButton from "./BotButton";
import BotMessage from "./BotMessage";
import { v4 as uuidv4 } from "uuid";
import CTAButton from "./CTAButton";
import botIcon from "../../../../../assets/legacy-short-icon.png";

function BotResponse(props) {
    const [clickedButtonID, setClickedButtonID] = useState(null);
    const card_list = props.data.card_data.map((card) => (
        <Card
            key={uuidv4()}
            heading={card?.heading}
            content={card?.content}
            image={card?.image}
        />
    ));

    const buttons_list = props.data.buttons.map((label, index) => (
        <BotButton
            key={uuidv4()}
            id={`button-${index}-${props.data.id}`}
            label={label}
            search_parameters={props.data.search_parameters}
            onChatSendBtnClick={props.onChatSendBtnClick}
            clickedButtonID={clickedButtonID}
            setClickedButtonID={setClickedButtonID}
            isLast={props.isLast}
            index={props.index}
            setState={props.setState}
            state={props.state}
        />
    ));

    const cta_list = props.data.cta.map((ctaItem, index) => (
        <CTAButton
            key={uuidv4()}
            id={`cta-${index}-${props.data.id}`}
            label={ctaItem.text}
            action={ctaItem.action}
        />
    ));

    const bot_response_content_div_style = {
        backgroundColor: props.colorTheme.bot.background,
        color: props.colorTheme.bot.text,
    };
    return (
        <>
            <div className="bot-response-outer-div">
                <div className="bot-response-inner-div">
                    <div className="bot-response-logo-outer-div">
                        <div className="bot-response-logo-inner-div">
                            <div className="bot-response-logo-div">
                                <img
                                    decoding="async"
                                    className="bot-response-logo-image"
                                    src={botIcon}
                                    alt=""
                                ></img>
                            </div>
                        </div>
                    </div>
                    {props.data.loading && (
                        <div className="flex flex-shrink-0 focus:outline-none mx-2 block rounded-full text-gray-500 hover:text-gray-800 hover:bg-gray-200 bg-gray-100 h-8 p-2 loader-div">
                            <div className="loader"></div>
                        </div>
                    )}
                    {!props.data.loading && (
                        <div
                            className="bot-response-content-div"
                            style={bot_response_content_div_style}
                        >
                            {props.data.message_before.trim().length > 0 && (
                                <BotMessage msg={props.data.message_before} />
                            )}
                            {card_list.length > 0 && card_list}
                            {props.data.message_after.trim().length > 0 && (
                                <BotMessage
                                    msg={props.data.message_after.trim()}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>

            {!props.data.loading &&
                props.data.buttons &&
                props.data.buttons.length > 0 && (
                    <div className="bot-response-buttons bot-buttons">
                        {buttons_list}
                    </div>
                )}

            {!props.data.loading &&
                props.data.cta &&
                props.data.cta.length > 0 && (
                    <div className="bot-response-buttons cta-buttons">
                        {cta_list}
                    </div>
                )}
        </>
    );
}

export default BotResponse;
