const CONSTANTS = {
    chatTypeWiseTitle: {
        "estate-planning": "Estate Planning",
        "find-someone": "Find Someone",
    },
    userType: {
        BOT: "AI",
        USER: "Human",
    },
    TOKEN_STORE_KEY: "LEGACY_AI_TOKEN",
    scrollChatBody: (id) => {
        const chatBodyDiv = document.getElementById(id);
        // const rect = chatBodyDiv.getBoundingClientRect();
        if (chatBodyDiv) {
            // chatBodyDiv.scrollTop = rect.height;

            // chatBodyDiv.scrollTo({
            //     top: rect.top + rect.height,
            //     behavior: "smooth",
            // });

            chatBodyDiv.scrollIntoView(false);
        }
    },
};
export default CONSTANTS;
