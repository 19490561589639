import React from "react";
import "./Footer.css";
import "@fontsource/source-sans-pro/900.css";

function Footer() {
    return (
        <div className="legacy-footer-outer-div">
            <div className="legacy-footer-inner-div">
                <div className="legacy-footer-grid-div">
                    <div className="legacy-footer-logo-div">
                        <div className="legacy-footer-legacy-logo-div"></div>
                        <div className="legacy-footer-social-media-logo-div">
                            <div className="legacy-footer-sm-logo-div">
                                <a
                                    className="legacy-footer-sm-logo-a"
                                    data-highlight="true"
                                    data-reset="button"
                                    href="https://www.facebook.com/Legacycom"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <div className="legacy-footer-sm-logo-inner-div"></div>
                                    <div className="legacy-footer-svg-outer-div">
                                        <div
                                            className="legacy-footer-svg-inner-div"
                                            name="Leading Icon"
                                        >
                                            <div className="legacy-footer-svg-div">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    focusable="false"
                                                    viewBox="0 0 24 24"
                                                    className="legacy-footer-svg"
                                                >
                                                    <path d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12c0 4.84 3.44 8.87 8 9.8V15H8v-3h2V9.5C10 7.57 11.57 6 13.5 6H16v3h-2c-.55 0-1 .45-1 1v2h3v3h-3v6.95c5.05-.5 9-4.76 9-9.95z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="legacy-footer-sm-logo-div">
                                <a
                                    className="legacy-footer-sm-logo-a"
                                    data-highlight="true"
                                    data-reset="button"
                                    href="https://www.youtube.com/legacycom"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <div className="legacy-footer-sm-logo-inner-div"></div>
                                    <div className="legacy-footer-svg-outer-div">
                                        <div
                                            className="legacy-footer-svg-inner-div"
                                            name="Leading Icon"
                                        >
                                            <div className="legacy-footer-svg-div">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    focusable="false"
                                                    viewBox="0 0 24 24"
                                                    className="legacy-footer-svg"
                                                >
                                                    <path d="M10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83-.25.9-.83 1.48-1.73 1.73-.47.13-1.33.22-2.65.28-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44-.9-.25-1.48-.83-1.73-1.73-.13-.47-.22-1.1-.28-1.9-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83.25-.9.83-1.48 1.73-1.73.47-.13 1.33-.22 2.65-.28 1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44.9.25 1.48.83 1.73 1.73z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="legacy-footer-links">
                        <div className="legacy-footer-links-heading">
                            <h3 className="legacy-footer-links-heading-h3">
                                Create an Obituary
                            </h3>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/place-an-obituary-anywhere-2?utm_campaign=homepagecta&amp;utm_source=legacy&amp;utm_medium=referral"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Submit an obituary
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/obit-writer?src=https://www.legacy.com"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Write an obituary
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/memorial-writing/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Obituary and Eulogy Tips
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/memorial-writing/obituary-templates"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Obituary templates
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/advice/obituary-examples/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Obituary examples
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/advice/how-to-write-an-obituary/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    How to write an obituary
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="legacy-footer-links">
                        <div className="legacy-footer-links-heading">
                            <h3 className="legacy-footer-links-heading-h3">
                                Find an Obituary
                            </h3>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/communities/high-school/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    High school
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/communities/college/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    College
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/funeral-homes"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Funeral home
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/us/obituaries/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    City
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/search-obituaries-in-local-newspapers/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Newspaper
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/category/news/celebrity-deaths/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Celebrities
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="legacy-footer-links">
                        <div className="legacy-footer-links-heading">
                            <h3 className="legacy-footer-links-heading-h3">
                                Funeral & Estate Planning
                            </h3>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/funeral-homes"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Find a funeral home
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/advice/estate-settlement-guide/?utm_source=legacy&utm_medium=homepage&utm_campaign=aftercare&utm_content=estate_homecta"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Estate planning guide
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://legacy.preplan.today/v1/typeOfNeed?utm_campaign=homepage&utm_source=legacy&utm_medium=referral&utm_content=navigation"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Plan & price a funeral
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/tag/help-with-grief-and-loss/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Help with grief & loss
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/tag/attending-a-funeral/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Attend a funeral
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://vault.efuneral.com/legacy?utm_source=Legacy.com&utm_medium=homepagenav&utm_campaign=vault"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Fund a funeral
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="legacy-footer-links">
                        <div className="legacy-footer-links-heading">
                            <h3 className="legacy-footer-links-heading-h3">
                                About Legacy
                            </h3>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/careers/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Careers
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/news-press/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    News & press
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/faq/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    FAQ
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/contact/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Contact us
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/privacy-policy/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Privacy policy
                                </a>
                            </p>
                        </div>
                        <div className="legacy-footer-links-item-div">
                            <p className="legacy-footer-links-item-p">
                                <a
                                    className="legacy-footer-links-item-a"
                                    href="https://www.legacy.com/terms-of-use/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    Terms of use
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="copy-right-outer-div">
                    <div className="copy-right-div">
                        <p className="copy-right-div-p">
                            ©2024 Legacy.com All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
