import CONSTANTS from "../../../const/constants";
import "./ChatBody.css";
import BotResponse from "./botResponse/BotResponse";
import UserResponse from "./userResponse/UserResponse";

function ChatBody(props) {
    let chat_body_styles = {};
    if (props.popup) {
        chat_body_styles.minHeight = "392px";
        chat_body_styles.padding = "24px";
    } else {
        chat_body_styles.minHeight = "80vh";
        // chat_body_styles.padding = "24px 64px 46px";
    }

    return (
        <div id="chat-body" className="chat-body" style={chat_body_styles}>
            <div id="chat-body-inner-div" className="chat-body-inner-div">
                {props.data.map((e, index) =>
                    e.from == CONSTANTS.userType.BOT ? (
                        <BotResponse
                            key={e.id}
                            data={e}
                            popup={props.popup}
                            colorTheme={props.colorTheme}
                            onChatSendBtnClick={props.onChatSendBtnClick}
                            isLast={index == props.data.length - 1}
                            index={index}
                            setState={props.setState}
                            state={props.state}
                        />
                    ) : (
                        <UserResponse
                            key={e.id}
                            data={e}
                            popup={props.popup}
                            colorTheme={props.colorTheme}
                        />
                    )
                )}
            </div>
        </div>
    );
}
export default ChatBody;
